<template>
  <Page>
    <template #header>
      <h1>Suivi de la journée</h1>
    </template>
    <template #body>

      <div class="is-size-6">
        <div class="hewder">
          <button class="button is-success is-light is-fullwidth" @click="previous()">Précédent</button>
          <button class="button is-info is-light is-fullwidth" @click="next()">Suivant</button>
        </div>
        <div class="hewder mt-5">

          <button class="button is-info is-light is-fullwidth" @click="choosePerson(1)">Nicolas</button>
          <button class="button is-danger is-light is-fullwidth" @click="choosePerson(2)">Elodie
          </button>
        </div>

        <h1 class="is-size-4 mt-6">Profil de {{ currentPerson == 1 ? 'Nicolas' : 'Elodie' }} - {{ currentDate }} </h1>
        <div class="table-container">
          <table class="table is-striped">
            <thead>
            <tr>
              <td>Nom</td>
              <td class="has-background-primary-light">G</td>
              <td class="has-background-danger-light	">L</td>
              <td class="has-background-success-light	">P</td>
              <td class="has-background-info-light	">Kcal</td>
              <td>Gr</td>
            </tr>
            </thead>
            <tr v-for="portion in portions">
              <td>{{ portion.name }}</td>
              <td class="has-background-primary-light	">{{ portion.glucide }}</td>
              <td class="has-background-danger-light	">{{ portion.lipide }}</td>
              <td class="has-background-success-light	">{{ portion.proteine }}</td>
              <td class="has-background-info-light	">{{ portion.kcal }}</td>
              <td>{{ portion.quantity }}</td>
              <td @click="deletePortion(portion.id)">❌</td>
            </tr>
            <tr class="has-text-weight-bold">
              <td>Total</td>
              <td class="has-background-primary-light	">{{ total.glucide }}</td>
              <td class="has-background-danger-light	">{{ total.lipide }}</td>
              <td class="has-background-success-light	">{{ total.proteine }}</td>
              <td class="has-background-info-light	">{{ total.kcal }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
              <tr class="has-text-weight-bold" v-if="currentPerson === 1">
                <td>Objectif</td>
                <td class="has-background-primary-light	">306</td>
                <td class="has-background-danger-light	">49.6</td>
                <td class="has-background-success-light	">124</td>
                <td class="has-background-info-light	">2168 </td>
            </tr>
          </table>
        </div>

        <button @click="showPortions" class="button is-primary">Ajouter une portion au menu du jour</button>

        <br>
        <br>
        <br>
        <div v-if="showAddPortion">
          <label>Recherche</label><br>
          <input type="text" v-model="search"><br>
          <div class="table-container mt-2">
            <table class="table">
              <thead>
              <tr>
                <td>Grammes</td>
                <td></td>
                <td>Nom</td>
                <td class="has-background-primary-light">G</td>
                <td class="has-background-danger-light">L</td>
                <td class="has-background-success-light">P</td>
                <td class="has-background-info-light">Kcal</td>
              </tr>
              </thead>
              <tr v-for="product in allProducts">
                <td>
                  <input placeholder="100" v-model="grammesOfPortion" type="text" class="input">
                </td>
                <td @click="addPortion(product.id)">➕</td>
                <td>{{ product.name }}</td>
                <td class="has-background-primary-light">{{ product.glucide }}</td>
                <td class="has-background-danger-light">{{ product.lipide }}</td>
                <td class="has-background-success-light">{{ product.proteine }}</td>
                <td class="has-background-info-light">{{ product.kcal }}</td>

              </tr>
            </table>
          </div>
        </div>


      </div>
    </template>
  </Page>
</template>

<script>
import axios from "axios";
import Page from "../Page.vue";
import {base} from "../api";
import {IonIcon} from '@ionic/vue';
import {chevronBackOutline} from 'ionicons/icons';

export default {
  name: "Home",
  components: {Page, IonIcon},
  data() {
    return {
      loaded :false,
      chevronBackOutline,
      currentPerson: 1,
      portions: [],
      total: {},
      percent: {},
      showAddPortion: false,
      allProducts: [],
      searchedProducts: [],
      grammesOfPortion: 0,
      currentOffset : 0,
      currentDate: '',
      search: '',
      objectif: {nicolas: {kcal: 2343, lipide: 234, proteine: 937, glucide: 1171}}

    }
  },

  async mounted() {
    await this.getJourney();
  },
  watch: {
    async search(newValue, oldValue) {
      this.allProducts = this.searchedProducts.filter(product => product.name.toLowerCase().includes(newValue.toLowerCase()))
    }
  },
  methods: {
    choosePerson(id) {
      this.currentPerson = id;
      this.getJourney();
    },
    async addPortion(idProduct) {
      let config = {
        headers: {
          'x-person': this.currentPerson,
        }
      }
      const request = {"quantity": this.grammesOfPortion, 'productId': idProduct};
      const response = await axios.post(base + '/portion', request, config)
      this.getJourney();
    },
    async addProduct() {
      const response = await axios.post(base + '/product', {
        'name': this.product.name,
        'kcal': this.product.kcal,
        'proteine': this.product.proteine,
        'lipide': this.product.lipide,
        'glucide': this.product.glucide,
      })
      this.product = {};
    },
    async getAllProducts() {
      const response = await axios.get(base + '/products');
      this.allProducts = response.data;
      this.searchedProducts = response.data
    },
    async showPortions() {
      this.showAddPortion = !this.showAddPortion;
      await this.getAllProducts();
    },

    async previous() {
      this.currentOffset = this.currentOffset + 1;
      await this.getJourney()
    },
    async next() {
      this.currentOffset = this.currentOffset - 1  <= 0 ? 0 : this.currentOffset - 1;
      await this.getJourney()
    },
    async getJourney() {
      let config = {
        headers: {
          'x-person': this.currentPerson,
        }
      }

      const response = await axios.get(base + '/journeys?offset='+this.currentOffset, config);
      const result = response.data;
      this.portions = [];
      this.total = {};
      this.currentDate = '';
      if(result.date) {
        this.currentDate = result.date;
      }
      if (result.portions && result.portions.length > 0 ) {
        this.currentDate = result.date;
        this.portions = result.portions;
        this.total = result.total
      }
    },
    async deletePortion(id) {
      const response = await axios.delete(base + '/portion/' + id);
      await this.getJourney()
    }
  }
}
</script>

<style scoped>

</style>