<template>
  <ion-menu side="end" content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item>
          <ion-label>
            <a href="/">Suivi de la journée</a>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <a href="/add-product">Ajouter un produit</a>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <a href="/see-products">Voir les produits</a>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <a href="/weight">Poids</a>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <a href="/graph">Graph</a>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            v1.1
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-page id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <slot name="header"></slot>
        </ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <slot name="body"></slot>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonNavLink,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import Home from "./pages/Home.vue";

export default {
  name: 'Page',
  components: {
    IonNavLink,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenu,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    Home,
    IonList,
    IonLabel,
    IonItem
  },
  data() {
    return {
      component: ''
    }

  }
};
</script>
