<template>
  <Page>
    <template #header>
      <h1>Ajouter un produit</h1>
    </template>
    <template #body>
      <div>
        <label>Nom</label><br>
        <input type="text" v-model="product.name"><br>
        <label>Lipides</label><br>
        <input type="text" v-model="product.lipide"><br>
        <label>Glucides</label><br>
        <input type="text" v-model="product.glucide"><br>
        <label>Protéines</label><br>
        <input type="text" v-model="product.proteine"><br>
        <label>Kcal</label><br>
        <input type="text" v-model="product.kcal"><br>
        <label>Par 100 Gr</label>
        <br>
        <button class="button is-warning" @click="addProduct">Ajouter le produit</button>
      </div>
    </template>
  </Page>


</template>

<script>
import axios from "axios";
import {base} from "../api";
import Page from "../Page.vue";
import {  toastController } from '@ionic/vue';

export default {
  name: 'AddProduct',
  components: {Page},
  data() {
    return {
      product: {},
    }
  },
  methods: {
    async presentToast(position, message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: position,
        color: color
      });

      await toast.present();
    },
    async addProduct() {
      try {
        const response = await axios.post(base + '/product', {
          'name': this.product.name,
          'kcal': this.product.kcal,
          'proteine': this.product.proteine,
          'lipide': this.product.lipide,
          'glucide': this.product.glucide,
        })

        await this.presentToast('top', 'Produit ajouté !', 'success')

      }catch (error) {
        this.presentToast('top', 'Une erreur est survenue :/', 'danger')
      }
      this.product = {};
    },
  }
}

</script>