<template>
  <Page>
    <template #header>
      <h1>Poids</h1>
    </template>
    <template #body>
      <div>
        Ajouter un poids<br>
        <input type="text" v-model="weight"><br>
        <select v-model="person">
          <option value="2" selected>Elodie</option>
          <option value="1">Nicolas</option>
        </select><br>
        <button class="button is-warning" @click="addWeight">Ajouter le poids</button>
      </div>
      <br>
      <br>
      <br>
      <ul>
        <li v-for="weight in weights" :key="weight.id">
          Le {{weight.date}} : {{weight.content}} kg
        </li>
      </ul>

    </template>
  </Page>


</template>

<script>
import axios from "axios";
import {base} from "../api";
import Page from "../Page.vue";
import {toastController} from '@ionic/vue';

export default {
  name: 'Weight',
  components: {Page},
  data() {
    return {
      person: 2,
      weight: 0,
      weights: []
    }
  },
  async mounted() {
    try{

    const response = await axios.get(base + '/weight/'+this.person);
    this.weights = response.data;
    }catch (error) {
      console.log(error);
    }
  },

  methods: {
    async addWeight() {
      const body = {
        content: this.weight,
      }
      try {

        const response = await axios.post(base + '/weight/' + this.person, body)
        await this.presentToast('top', 'Poids ajouté !', 'success')
        this.weights.push(response.data)
      } catch (error) {
        this.presentToast('top', 'Une erreur est survenue :/', 'danger')
      }
    },
    async presentToast(position, message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: position,
        color: color
      });

      await toast.present();
    },
  }
}

</script>