<template>
  <Page>
    <template #header>
      <h1>Graph</h1>
    </template>
    <template #body>
      <div>

        <div class="hewder mt-5">

          <button class="button is-info is-light is-fullwidth" @click="choosePerson(1)">Nicolas</button>
          <button class="button is-danger is-light is-fullwidth" @click="choosePerson(2)">Elodie
          </button>
        </div>

        <div class="mt-5">

          <Line :data="chartKcal" :options="chartOptions"/>

        </div>

        <div class="mt-5">
          <Line :data="chartMacro" :options="chartOptions"/>

        </div>

      </div>
    </template>
  </Page>


</template>

<script>
import axios from "axios";
import {base} from "../api";
import Page from "../Page.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import {Line} from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
export default {
  name: 'Graph',
  components: {Page, Line},
  data() {
    return {
      currentPerson: 1,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      // chartData: {}
      chartKcal: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      chartMacro: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
    }
  },
  async mounted() {
    this.getCharts();
  },
  methods: {
    choosePerson(id) {
      this.currentPerson = id;
      this.getCharts()
    },
    async getCharts() {
      let config = {
        headers: {
          'x-person': this.currentPerson,
        }
      }
      const response = await axios.get(base + '/charts', config);
      this.chartKcal = response.data.kcal
      this.chartMacro = response.data.macro

    }
  }
}

</script>