import {createRouter, createWebHistory} from '@ionic/vue-router';
import Home from "./pages/Home.vue";
import AddProduct from "./pages/AddProduct";
import Graph from "./pages/Graph";
import SeeProducts from "@/pages/SeeProducts";
import Weight from "@/pages/Weight";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/add-product',
    name: 'AddProduct',
    component: AddProduct,
  }, {
    path: '/see-products',
    name: 'SeeProducts',
    component: SeeProducts,
  },
  {
    path: '/weight',
    name: 'Weight',
    component: Weight,
  },
  {
    path: '/graph',
    name: 'Graph',
    component: Graph,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;