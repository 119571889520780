<template>
  <Page>
    <template #header>
      <h1>Tous les produits</h1>
    </template>
    <template #body>
      <div>
        <div class="table-container mt-2">
          <table class="table">
            <thead>
            <tr>
              <td>Nom</td>
              <td class="has-background-primary-light">G</td>
              <td class="has-background-danger-light">L</td>
              <td class="has-background-success-light">P</td>
              <td class="has-background-info-light">Kcal</td>
              <td>action</td>
            </tr>
            </thead>
            <tr v-for="product in products">
              <td><input style="width:50px;" v-model="product.name"></td>
              <td class="has-background-primary-light">
                <input style="width:40px;" v-model="product.glucide"></td>
              <td class="has-background-danger-light">
                <input style="width:40px;" v-model="product.lipide"></td>
              <td class="has-background-success-light">
                <input style="width:40px;" v-model="product.proteine"></td>
              <td class="has-background-info-light">
                <input style="width:40px;" v-model="product.kcal"></td>
              <td @click="update(product)">✔️</td>
            </tr>
          </table>
        </div>
        <!--        <label>Nom</label><br>-->
        <!--        <input type="text" v-model="product.name"><br>-->
        <!--        <label>Lipides</label><br>-->
        <!--        <input type="text" v-model="product.lipide"><br>-->
        <!--        <label>Glucides</label><br>-->
        <!--        <input type="text" v-model="product.glucide"><br>-->
        <!--        <label>Protéines</label><br>-->
        <!--        <input type="text" v-model="product.proteine"><br>-->
        <!--        <label>Kcal</label><br>-->
        <!--        <input type="text" v-model="product.kcal"><br>-->
        <!--        <label>Par 100 Gr</label>-->
        <!--        <br>-->
        <!--        <button class="button is-warning" @click="addProduct">Ajouter le produit</button>-->
      </div>
    </template>
  </Page>


</template>

<script>
import axios from "axios";
import {base} from "../api";
import Page from "../Page.vue";
import {toastController} from '@ionic/vue';

export default {
  name: 'SeeProducts',
  components: {Page},
  data() {
    return {
      products: {},
    }
  },
  async mounted() {
    const response = await axios.get(base + '/products');
    this.products = response.data;
  },

  methods: {
    async update(product) {
      const body = {
        name: product.name,
        kcal: product.kcal,
        glucide: product.glucide,
        proteine: product.proteine,
        lipide: product.lipide,
      }
      try {

        const response = await axios.patch(base + '/product/' + product.id, body)
        await this.presentToast('top', 'Produit modifié !', 'success')
      } catch (error) {
        this.presentToast('top', 'Une erreur est survenue :/', 'danger')
      }
    },
    async presentToast(position, message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: position,
        color: color
      });

      await toast.present();
    },
    async addProduct() {
      try {
        const response = await axios.post(base + '/product', {
          'name': this.product.name,
          'kcal': this.product.kcal,
          'proteine': this.product.proteine,
          'lipide': this.product.lipide,
          'glucide': this.product.glucide,
        })

        await this.presentToast('top', 'Produit ajouté !', 'success')

      } catch (error) {
        this.presentToast('top', 'Une erreur est survenue :/', 'danger')
      }
      this.product = {};
    },
  }
}

</script>